import { render, staticRenderFns } from "./AgreementPopup.vue?vue&type=template&id=37eda958&scoped=true&"
import script from "./AgreementPopup.vue?vue&type=script&lang=js&"
export * from "./AgreementPopup.vue?vue&type=script&lang=js&"
import style0 from "./AgreementPopup.vue?vue&type=style&index=0&id=37eda958&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "37eda958",
  null
  
)

export default component.exports